<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="LOADER"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Update Category">
            <validation-observer ref="updateCategoryValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          name="name"
                          placeholder="Enter category name"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Description"
                      label-for="description"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="description"
                        rules="required"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="form.description"
                          name="description"
                          placeholder="Enter description"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Image"
                      label-for="image"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="image"
                      >
                        <b-form-file
                          id="image"
                          v-model="form.image"
                          accept=".jpg, .png, .gif .svg"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Update
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const appModule = createNamespacedHelpers('app')
const categoryModule = createNamespacedHelpers('category')

export default {
  data() {
    return {
      form: {
        name: '',
        description: '',
        image: null,
      },
    }
  },
  computed: {
    ...appModule.mapState(['LOADER']),
  },
  async mounted() {
    this.UPDATE_LOADER(true)
    await this.fetchCategory()
    this.UPDATE_LOADER(false)
  },
  methods: {
    ...appModule.mapMutations(['UPDATE_LOADER']),
    ...categoryModule.mapActions(['FETCH_SINGLE_CATEGORY', 'UPDATE_CATEGORY']),

    async fetchCategory() {
      try {
        this.UPDATE_LOADER(true)
        const resp = await this.FETCH_SINGLE_CATEGORY(this.$route.params.id)
        this.form.name = resp.name
        this.form.description = resp.description
        this.UPDATE_LOADER(false)
      } catch (error) {
        this.UPDATE_LOADER(false)
      }
    },

    onSubmit() {
      this.$refs.updateCategoryValidator.validate().then(async success => {
        if (success) {
          this.UPDATE_LOADER(true)
          let resp = null
          if (this.form.image) {
            const formData = new FormData()
            formData.append('name', this.form.name)
            formData.append('description', this.form.description)
            formData.append('image', this.form.image)
            resp = await this.UPDATE_CATEGORY({ id: this.$route.params.id, data: formData })
          } else {
            const newFrom = {
              name: this.form.name,
              description: this.form.description,
            }
            resp = await this.UPDATE_CATEGORY({ id: this.$route.params.id, data: newFrom })
          }
          if (resp) {
            this.$nextTick(() => {
              this.$refs.updateCategoryValidator.reset()
            })
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Category Updated!',
                icon: 'checkIcon',
                variant: 'success',
                text: 'The category has been updated successfully!',
              },
            })
          }
          this.UPDATE_LOADER(false)
        }
      })
    },
  },
}
</script>
